import { useWeb3Store } from "@/store/web3";
import { TokenType } from "@/config/token-types";
import utilitiesMetadata from "@/config/utilities-metadata";
import { useApesStorageApi } from "@/api/useApesStorageApi";
import { Config } from "@/config";

export const fetchUtilities = async (utilitiesBalance) => {
  const offchainUtilitiesCount = await fetchOffChainUtilities();

  /*
      utility #20 - 23 METH MAKER (20 North, 21 South, 22 East, 23 West)
      utility #30 - 33 RESURRECTION
      utility #40 - 43 BREED REPLENISH
  */
  
  const stackedOrbs = loadMultipleStackableUtility(offchainUtilitiesCount, [2], TokenType.MERGER_ORB)
  const stackedIncubators = loadMultipleStackableUtility(offchainUtilitiesCount, [1], TokenType.INCUBATOR)

  const stackedMethMachines = loadMultipleStackableUtility(offchainUtilitiesCount, [20, 21, 22, 23], TokenType.METH_MACHINE)
  const stackedResurrections = loadMultipleStackableUtility(offchainUtilitiesCount, [30, 31, 32, 33], TokenType.TEEN_RESURRECTION)
  const stackedBreedings = loadMultipleStackableUtility(offchainUtilitiesCount, [40, 41, 42, 43], TokenType.BREEDING_REPLENISHMENT)

  const { items: forgeBlocks, balance: forgeBlocksBalance } =
    loadStackableUtility(offchainUtilitiesCount, 3, TokenType.FORGE_BLOCK);

  const { items: yellowIncubators } =
    loadStackableUtility(offchainUtilitiesCount, 4, TokenType.YELLOW_INCUBATOR);

  const { items: creatorPassports } =
    loadStackableUtility(offchainUtilitiesCount, 5, TokenType.CREATOR_PASSPORT);

  return {
    methMachines: stackedMethMachines,
    teenResurrections: stackedResurrections,
    breedingReplenishments: stackedBreedings,
    orbs: stackedOrbs,
    incubators: stackedIncubators,
    forgeBlocksBalance,
    forgeBlocks,
    yellowIncubators,
    creatorPassports
  };
};

async function fetchOffChainUtilities() {
  const result = await useApesStorageApi({ throw: true }).exec({
    url: "/wallet/utilities",
    params: {
      wallet: useWeb3Store().collectionWallet,
      network: Config.network.name,
    },
  });
  return result;
}
function loadMultipleStackableUtility(offchainUtilitiesCount, ids, type) {
  const loadedItems = []

  for (let i = 0; i < ids.length; i++) {
    const id = ids[i]
    const {items} = loadStackableUtility(offchainUtilitiesCount, id, type)

    loadedItems.push(...items)
  }

  return loadedItems
}
function loadStackableUtility(offchainUtilitiesCount, id, type) {
  const items = [];
  const balanceOnChain = +(offchainUtilitiesCount.onChain?.[id] || 0);
  const balanceOffChain = +(offchainUtilitiesCount[id] || 0);
  const balance = {
    onChain: balanceOnChain,
    offChain: balanceOffChain,
    all: balanceOnChain + balanceOffChain,
  };

  if (balanceOffChain > 0 || balanceOnChain > 0) {
    items.push({
      ...utilitiesMetadata[id],
      id,
      offChainCount: balanceOffChain,
      onChainCount: balanceOnChain,
      isUtility: true,
      type,
    });
  }
  return { items, balance };
}

async function loadIncubators(count, offchainCount = 0) {
  if (!count && !offchainCount) {
    return [];
  }
  const metadata = utilitiesMetadata[1];
  const utilityItem = {
    id: 1,
    isUtility: true,
    type: TokenType.INCUBATOR,
    ...metadata,
  };
  const incubators = [];
  for (let i = 0; i < count; i++) {
    incubators.push(utilityItem);
  }
  for (let i = 0; i < offchainCount; i++) {
    incubators.push({ ...utilityItem, isOffChain: true });
  }
  return incubators;
}

async function loadOrbs(count, offchainCount = 0) {
  if (!count && !offchainCount) {
    return [];
  }
  const metadata = utilitiesMetadata[2];
  const orbs = [];
  const utilityItem = {
    id: 2,
    isUtility: true,
    type: TokenType.MERGER_ORB,
    ...metadata,
  };
  for (let i = 0; i < count; i++) {
    orbs.push(utilityItem);
  }
  for (let i = 0; i < offchainCount; i++) {
    orbs.push({ ...utilityItem, isOffChain: true });
  }
  return orbs;
}

async function loadGamingUtilities(counts, ids, type, offchainCount) {
  const items = [];

  for (let i = 0; i < counts.length; i++) {
    const metadata = utilitiesMetadata[ids[i]];
    const utilityItem = {
      id: ids[i],
      isUtility: true,
      type,
      ...metadata,
    };
    // on-chain
    for (let j = 0; j < counts[i]; j++) {
      items.push({ ...utilityItem });
    }
    // off-chain
    for (let j = 0; j < offchainCount[ids[i]]; j++) {
      items.push({
        ...utilityItem,
        isOffChain: true,
      });
    }
  }
  return items;
}

export const TokenType = {
  BOTBOG: "BOTBOG",
  BOTBEVO: "BOTBEVO",
  BOTBBEARS: "BOTBBEARS",
  BOTB_SPECIAL: "BOTB_SPECIAL",
  GENESIS: "GENESIS",
  GOD: "GOD",
  TEEN: "TEEN",
  PARTNER: "PARTNER",
  INCUBATOR: "INCUBATOR",
  MERGER_ORB: "MERGER_ORB",
  TEEN_RESURRECTION: "TEEN_RESURRECTION",
  BREEDING_REPLENISHMENT: "BREEDING_REPLENISHMENT",
  METH_MACHINE: "METH_MACHINE",
  APE: "APE",
  GOD_APE: "GOD_APE",
  FORGE_BLOCK: "FORGE_BLOCK",
  YELLOW_INCUBATOR: "YELLOW_INCUBATOR",
  CREATOR_PASSPORT: "CREATOR_PASSPORT",
};
